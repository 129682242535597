import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuardService, FormbuilderGuardService, ReportingGuardService } from '@shared/services/api/auth.service';
import { HtmlEditorComponent } from './pages/html-editor/htmlEditor.component';
import { TaskLinkComponent } from './pages/tasklink/tasklink.component';

const routes: Routes = [
    { path: '', redirectTo: 'app/home', pathMatch: 'full' },
    {
        path: "app/home",
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: true },
    },
    {
        path: "app/analytics",
        loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: true },
    },
    {
        path: "app/analyticsmobile",
        loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
        canActivate: [FormbuilderGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
   
    {
        path: "app/menu/:id/:formId/:schemaId",
        loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: true },
    },
    {
        path: "app/formbuilder",
        loadChildren: () => import('./pages/formbuilder/formbuilder.module').then(m => m.FormbuilderModule),
        canActivate: [FormbuilderGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
    {
        path: "app/reportdesigner",
        loadChildren: () => import('./pages/report/_report_designer/reportdesigner.module').then(m => m.ReportDesignerModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
    {
        path: "mobilwhitepage/:reportGuid",
        loadChildren: () => import('./pages/mobil-whitepage/mobilwhitepage.module').then(m => m.MobileWhitePageModule),

       // component: MobilWhitePageComponent,
        data: { animation: true, useTheme: false }
    },
    {
        path: "app/report",
        loadChildren: () => import('./pages/report/_report_viewer/reportviewer.module').then(m => m.ReportViewerModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: true }
    },
    {
        path: "app/pagedesigner",
        loadChildren: () => import('./pages/board/page-designer/page-designer.module').then(m => m.PageDesignerModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
    {
        path: "app/pageviewer",
        loadChildren: () => import('./pages/board/page-viewer/page-viewer.module').then(m => m.PageViewerModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: true }
    },
    {
        path: "app/pageviewermobile",
        loadChildren: () => import('./pages/board/page-viewer/page-viewer.module').then(m => m.PageViewerModule),       
        canActivate: [FormbuilderGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
    {
        path: "app/profile",
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: true }
    },
   {
        path: "mobildashboard/:reportId/:userId",
        loadChildren: () => import('./pages/mobile-dashboard/mobile-dashboard.module').then(m => m.MobileDashboardModule),
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
    {
        path: "app/filemanagement",
        loadChildren: () => import('./pages/filemanagement/filemanagement.module').then(m => m.FileManagementModule),
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },
    {
        path: "login",
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
        data: { animation: true, useTheme: false }
    },
    {
        path: "outlook",
        loadChildren: () => import('./pages/outlook/outlook.module').then(m => m.OutlookModule),
        data: { animation: true, useTheme: false }
    },
    {
        path: "app/email",
        loadChildren: () => import('./pages/email/email.module').then(m => m.EmailModule),
        canActivate: [AuthGuardService],
        //pathMatch: 'full',
        data: { animation: true, useTheme: true }
    },
    {
        path: "htmleditor",
        loadChildren: () => import('./pages/html-editor/htmlEditor.module').then(m => m.HtmlEditorModule),
        canActivate: [AuthGuardService],
        component: HtmlEditorComponent,
        pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },   
    {
        path: "tasklink/:guid",
        loadChildren: () => import('./pages/tasklink/tasklink.module').then(m => m.TasklinkModule),
        //component: TaskLinkComponent,
        //pathMatch: 'full',
        data: { animation: true, useTheme: false }
    },   
    { path: '**', redirectTo: 'login' }
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false }),
    ],
    exports: [RouterModule],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]

})
export class AppRoutingModule { }